import React from "react";
import cardMouseEffect from "common/cardMouseEffect";
import { thumparallaxDown } from "common/thumparallax";

const MinimalArea = () => {
  React.useEffect(() => {
    cardMouseEffect(document.querySelectorAll(".feat .items"));
    setTimeout(() => {
      thumparallaxDown();
    }, 1000);
  }, []);

  return (
    <section className="min-area sub-bg">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="img">
              <img
                className="thumparallax-down"
                src="/img/0001.jpg"
                alt=""
              />
            </div>
          </div>
          <div className="col-lg-6 valign">
            <div className="content pt-0">
              <h4 className="wow color-font">Why Connectified?</h4>
              {/* <p className="wow txt" data-splitting>
                Our creative Ad agency is ranked among the finest in the US. We
                cultivate smart ideas for start-ups and seasoned players.
              </p> */}
              <ul className="feat">
                <li className="wow fadeInUp" data-wow-delay=".2s">
                  <p>
                    In order for IoT initiatives to prove truly effective and run efficiently we advise that businesses seek professional advice and support right from the first step. Engaging with a brand that focuses wholly on IoT can significantly streamline the connected solution development and eliminate downstream
bottlenecks when looking to harness the data from these solutions. Regardless of where you are in your IoT journey, using the right IoT solutions approach from the get-go will provide peace-of-mind and give your business the confidence to make targeted time and effort investments that will lead to quicker payback and provide a better return.
                  </p>
                  {/* <p>
                    luctus massa ipsum at tempus eleifend congue lectus bibendum
                  </p> */}
                </li>
                <li className="wow fadeInUp" data-wow-delay=".4s">
                  <p>
                  Connectified is a simple, pain-free, and dependable way to fulfil all of your IoT needs. Connectified includes IoT professional services such as project management, solution design and architecture through to field installation and device onboarding. In addition, we offer for the first-time an IoT-focused managed services suite that includes mission critical support with around-the-clock availability. Furthermore, all the individual bits and bobs for your solution can be found via our soon to be launched Connectified web portal which lists a growing portfolio of carefully selected compatible device, hardware and connectivity options.
                  </p>
               
                </li>
                <li className="wow fadeInUp" data-wow-delay=".6s">
                 
                  <p>
                  With over 30 years of experience in dealing with M2M communications and IoT, the team here at M2M Telecoms has the right expertise to meet all your IoT needs. We look forward to becoming our customers’ most trusted and dependable partner as they forge a way forward on their unique IoT journey.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MinimalArea;
